import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Function to set meta tags dynamically
const setMetaTags = (title, description) => {
  document.title = title;

  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    document.head.appendChild(metaDescription);
  }
  metaDescription.content = description;

  let canonical = document.querySelector('link[rel="canonical"]');
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.rel = 'canonical';
    document.head.appendChild(canonical);
  }
  canonical.href = window.location.href;
};

const FDAApp = () => {
  const [query, setQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10);
  const [chartData, setChartData] = useState({ manufacturers: [], categories: [] });

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch data (top 20 manufacturers and categories)
  useEffect(() => {
    fetch('https://medvertise.com/fetch_fda_data.php?chart_data=true')
      .then((response) => response.json())
      .then((data) => setChartData(data))
      .catch((error) => console.error('Error fetching chart data:', error));
  }, []);

  // Update SEO information based on URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const applicantParam = params.get('applicant');
    const categoryParam = params.get('category');

    if (applicantParam) {
      setMetaTags(
        `FDA 510(k) Search: Manufacturer - ${applicantParam}`,
        `Search results for FDA 510(k) submissions by the manufacturer ${applicantParam}.`
      );
      handleManufacturerSearch(1, applicantParam);
    } else if (categoryParam) {
      setMetaTags(
        `FDA 510(k) Search: Category - ${categoryParam}`,
        `Search results for FDA 510(k) submissions by product category ${categoryParam}.`
      );
      handleCategorySearch(1, categoryParam);
    } else {
      setMetaTags(
        'FDA 510(k) Search',
        'Search FDA 510(k) premarket notifications by manufacturer or product category.'
      );
    }
  }, [location.search]);

  // Handle search by manufacturer
  const handleManufacturerSearch = (page = 1, manufacturerQuery = query) => {
    setLoading(true);
    const offset = (page - 1) * resultsPerPage;
    const searchUrl = `https://medvertise.com/fetch_fda_data.php?applicant=${encodeURIComponent(manufacturerQuery)}&limit=${resultsPerPage}&offset=${offset}`;

    fetch(searchUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResults(data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  // Handle search by product category
  const handleCategorySearch = (page = 1, categoryQueryParam = categoryQuery) => {
    setLoading(true);
    const offset = (page - 1) * resultsPerPage;
    const searchUrl = `https://medvertise.com/fetch_fda_data.php?category=${encodeURIComponent(categoryQueryParam)}&limit=${resultsPerPage}&offset=${offset}`;

    fetch(searchUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResults(data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  // Update manufacturer query
  const handleManufacturerInputChange = (e) => {
    setQuery(e.target.value);
  };

  // Update product category query
  const handleCategoryInputChange = (e) => {
    setCategoryQuery(e.target.value);
  };

  // Sort results by decision date
  const handleSort = () => {
    const sortedResults = [...results].sort((a, b) => {
      const dateA = new Date(a.decisiondate);
      const dateB = new Date(b.decisiondate);

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setResults(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Handle pagination click
  const handlePageChange = (newPage, searchType) => {
    setCurrentPage(newPage);
    if (searchType === 'applicant') {
      handleManufacturerSearch(newPage);
    } else if (searchType === 'category') {
      handleCategorySearch(newPage);
    }
  };

  // Function to render a bar chart-like structure using divs and hyperlinks
  const renderBarChart = (data, type) => {
    return data.map((item, index) => {
      const name = type === 'manufacturer' ? item.applicant : item.category;
      const count = item.count;
      const link = `?${type === 'manufacturer' ? 'applicant' : 'category'}=${encodeURIComponent(name)}`;
      const widthPercentage = (count / Math.max(...data.map(d => d.count))) * 100;
  
      return (
        <div key={index} style={styles.barContainer}>
          <a href={link} style={styles.barLabel}>
            {name} ({count})
          </a>
          <div style={{ ...styles.bar, width: `${widthPercentage}%` }} />
        </div>
      );
    });
  };
  

  if (loading) {
    return <div>Loading data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={styles.container}>
      {/* Search by Manufacturer */}
      <div style={styles.searchContainer}>
        <h1>Search by Manufacturer</h1>
        <input
          style={styles.input}
          type="text"
          value={query}
          onChange={handleManufacturerInputChange}
          placeholder="Search by manufacturer"
        />
        <button style={styles.button} onClick={() => handleManufacturerSearch()}>
          Search
        </button>
      </div>

      {/* Search by Product Category */}
      <div style={styles.searchContainer}>
        <h1>Search by Product Category</h1>
        <input
          style={styles.input}
          type="text"
          value={categoryQuery}
          onChange={handleCategoryInputChange}
          placeholder="Search by product category"
        />
        <button style={styles.button} onClick={() => handleCategorySearch()}>
          Search
        </button>
      </div>

      {/* Table of results */}
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Applicant</th>
            <th style={styles.th}>Device Name</th>
            <th style={styles.th} onClick={handleSort}>Decision Date {sortOrder === 'asc' ? '↑' : '↓'}</th>
            <th style={styles.th}>Product Category</th>
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            results.map((item) => (
              <tr key={item.applicant + item.devicename}>
                <td style={styles.td}>{item.applicant}</td>
                <td style={styles.td}>{item.devicename}</td>
                <td style={styles.td}>{item.decisiondate}</td>
                <td style={styles.td}>{item.product_category || 'No Category'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={styles.td}>
                No results found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div style={styles.paginationContainer}>
        <button style={styles.button} onClick={() => handlePageChange(currentPage - 1, query ? 'applicant' : 'category')} disabled={currentPage === 1}>
          Previous
        </button>
        <span style={styles.pageIndicator}>Page {currentPage}</span>
        <button style={styles.button} onClick={() => handlePageChange(currentPage + 1, query ? 'applicant' : 'category')}>
          Next
        </button>
      </div>

      {/* Display Manufacturers and Categories as Bar Charts */}
      <div>
        <h2>Top 20 Manufacturers</h2>
        {renderBarChart(chartData.manufacturers, 'manufacturer')}

        <h2>Top 20 Categories</h2>
        {renderBarChart(chartData.categories, 'category')}
      </div>
    </div>
  );
};

// Style object for modern and mobile-friendly design
const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
  searchContainer: {
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    width: '80%',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 15px',
    marginLeft: '10px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  th: {
    padding: '10px',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    border: '1px solid #ddd',
  },
  td: {
    padding: '10px',
    border: '1px solid #ddd',
  },
  paginationContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageIndicator: {
    margin: '0 10px',
    fontSize: '18px',
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  barLabel: {
    width: '30%',
    textAlign: 'left',
    marginRight: '10px',
  },
  bar: {
    height: '20px',
    backgroundColor: '#007BFF',
    borderRadius: '4px',
  },
};

export default FDAApp;
